import React, { useEffect, useState } from "react";
import { TextField, Typography, withStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@material-ui/core";
import { checkIfItemIsActive } from "../../funcoes";
import { txtAjudaCreep, txtAjudaNaoCreep } from "../../mocks/mockOpcoesRebanho";
import SelectDropdown from "components/SelectDropdown";
import BannerNameExists from "./bannerNameExists";

const FormCadastroFormulacao = (props) => {
  const {
    classes,
    quantidadeTotal,
    setQuantidadeTotal,
    quantidadeCalculada,
    setFormulacaoValida,
    exibirCreep,
    exibirSemiConfinamento,
    novaFormulacao,
    setNovaFormulacao,
    formulacao,
    categoriasRebanho,
    epocas,
    nameExistsError,
    sameNameFormula,
    createCopySameName,
  } = props;

  const OPCOES_EMBALAGENS = [
    {
      value: 1,
      label: "GRANEL",
    },
    {
      value: 0,
      label: "ENSACADO",
    },
  ];

  // hooks usados para controle
  const [nomeFormula, setNomeFormula] = useState("");
  const [nomeFormulaErro, setNomeFormulaErro] = useState("");
  const [pesoSaco, setPesoSaco] = useState("");
  const [pesoSacoError, setPesoSacoError] = useState("");
  const [embalagem, setEmbalagem] = useState(1);
  const [embalagemError, setEmbalagemError] = useState(false);
  const [opcoesEpoca, setOpcoesEpoca] = useState([]);
  const [epocaSelecionada, setEpocaSelecionada] = useState([]);
  const [epocaSelecionadaError, setEpocaSelecionadaError] = useState(false);
  const [opcoesRebanhoTratado, setOpcoesRebanhoTratado] = useState([]);
  const [opcoesRebanhoTratadoError, setOpcoesRebanhoTratadoError] =
    useState(false);
  const [opcoesRebanhoSelecionada, setOpcoesRebanhoSelecionada] = useState([]);
  const [nameExistsModal, setNameExistsModal] = useState(false);
  const [quantidadeTotalError, setQuantidadeTotalError] = useState(false);
  const [formulacaoValidaParaCopia, setFormulacaoValidaParaCopia] =
    useState(true);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  useEffect(() => {
    setNameExistsModal(nameExistsError);
  }, [nameExistsError]);

  // monta o filtro de rebanhos conforme as toggle da fazenda
  useEffect(() => {
    let tiposRebanhoFiltrados = categoriasRebanho;

    // remove o creep se a fazenda não tiver
    if (!exibirCreep) {
      tiposRebanhoFiltrados = (tiposRebanhoFiltrados || []).filter(
        (tipo) => tipo.Descricao.toLowerCase() !== "creep"
      );
    }

    // remove o semiConfinamento se a fazenda não tiver
    if (!exibirSemiConfinamento) {
      tiposRebanhoFiltrados = (tiposRebanhoFiltrados || []).filter(
        (tipo) => tipo.Descricao.toLowerCase() !== "semiconfinamento"
      );
    }

    // cria a lista de rebanho com base no que o backend manda
    const opcoesRebanho = (tiposRebanhoFiltrados || []).map((categoria) => {
      const _isCreep = categoria.Descricao.toUpperCase() === "CREEP";
      return {
        label: categoria.Descricao.toUpperCase(),
        value: categoria.IdCategoria,
        disabledHelpText: _isCreep ? txtAjudaCreep : txtAjudaNaoCreep,
        active: (values) => checkIfItemIsActive(values, _isCreep),
      };
    });

    setOpcoesRebanhoTratado(opcoesRebanho);

    // cria a lista de epoca com base no que o backend manda
    const epocasTratadas = (epocas || []).map((epoca) => ({
      label: epoca.Descricao.toUpperCase(),
      value: epoca.IdEpoca,
    }));
    setOpcoesEpoca(epocasTratadas);
  }, [categoriasRebanho, epocas]);

  // verifica se tem formulacao se tiver preenche os campos automaticamente
  useEffect(() => {
    if (formulacao) {
      const rebanhoSelecionado = (categoriasRebanho || []).filter((categoria) =>
        (formulacao.Categoria || []).find(
          (reb) => reb.IdCategoria === categoria.IdCategoria
        )
      );

      const categoriaSelecinada = rebanhoSelecionado.map(
        (rs) => rs.IdCategoria
      );
      // altera os campos para selecionar
      setOpcoesRebanhoSelecionada(categoriaSelecinada);

      const epocaSelecionada = (epocas || []).filter((epoca) =>
        (formulacao.Epocas || []).find((epoc) => epoc.IdEpoca === epoca.IdEpoca)
      );

      const epocaTratada = epocaSelecionada.map((ep) => ep.IdEpoca);

      const temPesoSaco =
        formulacao.PesoSaco !== null && formulacao.PesoSaco !== undefined;

      // altera os campos para selecionar
      setEpocaSelecionada(epocaTratada);
      setEmbalagem(formulacao.Granel || !temPesoSaco ? 1 : 0);
      setNomeFormula(formulacao.Nome);
      setPesoSaco(temPesoSaco ? formulacao.PesoSaco : "");
    }
  }, [opcoesEpoca, opcoesRebanhoTratado]);

  // verifica a obrigatoriedade de todos os campos
  useEffect(() => {
    const consumo = Number(quantidadeTotal);
    const nomeValido =
      !nameExistsError && nomeFormula !== "" && nomeFormula.length < 61;
    const embalagemValido =
      embalagem === 1 || (embalagem === 0 && pesoSaco !== "");
    const pesoSacoValido = embalagem === 1 || pesoSaco !== "";
    const rebanhoValido = opcoesRebanhoSelecionada.length > 0;
    const epocaValido = epocaSelecionada.length > 0;
    const consumoValido = consumo > 0;

    if (formulacao) {
      setNomeFormulaErro(!nomeValido);
      setEmbalagemError(!embalagemValido);
      setPesoSacoError(!pesoSacoValido);
      setOpcoesRebanhoTratadoError(!rebanhoValido);
      setEpocaSelecionadaError(!epocaValido);
      setQuantidadeTotalError(!consumoValido);
    }

    setFormulacaoValidaParaCopia(
      nomeFormula !== "" &&
      embalagemValido &&
      rebanhoValido &&
      epocaValido &&
      consumoValido
    );

    if (
      nomeValido &&
      embalagemValido &&
      rebanhoValido &&
      epocaValido &&
      consumoValido
    ) {
      setFormulacaoValida(false);
    } else {
      setFormulacaoValida(true);
    }

    setNovaFormulacao({
      ...novaFormulacao,
      Nome: nomeFormula,
      PesoSaco: pesoSaco === "" ? null : pesoSaco,
      ConsumoDiario: quantidadeTotal,
      Epocas: epocaSelecionada,
      Granel: embalagem,
      RebanhosAlvo: opcoesRebanhoSelecionada,
    });
  }, [
    nameExistsError,
    nomeFormula,
    pesoSaco,
    embalagem,
    epocaSelecionada,
    opcoesRebanhoSelecionada,
    quantidadeTotal,
  ]);

  // funcao seletora da epoca
  const handlerChangeEpoca = (novasEpocas) => {
    const epocaSelecionada = opcoesEpoca
      .filter((v) => novasEpocas.some((f) => f === v.value))
      .map((epoca) => epoca.value);

    setEpocaSelecionada(epocaSelecionada);
  };

  // funcao seletora do rebanho
  const handlerChangeOpcoesRebanho = (novosRebanho) => {
    const rebanhoSelecionada = opcoesRebanhoTratado
      .filter((v) => novosRebanho.some((f) => f === v.value))
      .map((rebanho) => rebanho.value);

    setOpcoesRebanhoSelecionada(rebanhoSelecionada);
  };

  const handlerChangeEmbalagem = (embalagem) => {
    if (embalagem) setPesoSaco("");
    setEmbalagem(embalagem);
  };

  const handleNameExistsClose = () => {
    setNameExistsModal(false);
  };

  const handleCopySameName = () => {
    createCopySameName();
  };

  const EmbalagemLabel = () => (
    <div className={classes.embalagemLabel}>
      <span>Embalagem*</span>
    </div>
  );

  const handleSetNomeFormula = ({ target }) => {
    if (target.value.length <= 61) {
      setNomeFormula(target.value);
    }
  };

  const handlePesoSacoChange = ({ target }) => {
    const value = target.value;
    if (value === "0") {
      setConfirmationModalOpen(true);
    } else {
      setPesoSaco(value);
    }
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmationModalConfirm = () => {
    setPesoSaco("0");
    setConfirmationModalOpen(false);
  };

  return (
    <div className={classes.formCadastroWrapper}>
      <Typography className={classes.tituloFormFormulacao}>
        NOVA FORMULAÇÃO
      </Typography>
      <div className={classes.formFormulacao}>
        <TextField
          id="id-nome-formula-input"
          InputLabelProps={{ shrink: true }}
          type="text"
          required
          label="Nome da fórmula"
          className={classes.formulaInput}
          placeholder="Digite um nome"
          onChange={handleSetNomeFormula}
          value={nomeFormula}
          error={nameExistsError || nomeFormulaErro || nomeFormula.length > 60}
          helperText={
            nomeFormula.length > 60
              ? `O nome da fórmula deve conter no máximo 60 caracteres`
              : `${nomeFormula.length}/60`
          }
        />
        <div className={classes.selectTipos}>
          <SelectDropdown
            id="embalagemFormulacao"
            testId="embalagemFormulacao-input"
            error={embalagemError}
            label={{
              text: EmbalagemLabel(),
              requided: true,
            }}
            options={OPCOES_EMBALAGENS}
            value={embalagem}
            onChange={handlerChangeEmbalagem}
          />
        </div>
        <TextField
          id="id-peso-saco-input"
          InputLabelProps={{ shrink: true }}
          label="Peso do saco(KG)"
          className={classes.pesoInput}
          disabled={embalagem === 1}
          placeholder="Insira o peso"
          type="number"
          onChange={handlePesoSacoChange}
          value={pesoSaco}
          error={pesoSacoError}
        />
        <div className={classes.multiSelectTipos}>
          <SelectDropdown
            isMulti
            isClearable
            isSearchable
            label={{ text: "Rebanho Alvo", required: true }}
            error={opcoesRebanhoTratadoError}
            options={opcoesRebanhoTratado}
            value={opcoesRebanhoSelecionada}
            onChange={handlerChangeOpcoesRebanho}
          />
        </div>
        <div className={classes.multiSelectTipos}>
          <SelectDropdown
            isMulti
            isClearable
            isSearchable
            id="epocaFormulacao"
            label={{ text: "Época", required: true }}
            error={epocaSelecionadaError}
            options={opcoesEpoca}
            value={epocaSelecionada}
            onChange={handlerChangeEpoca}
          />
        </div>
        <div className={classes.consumoFormulaWrapper}>
          <TextField
            id="id-consumo-objetivo-input"
            InputLabelProps={{ shrink: true }}
            label="Consumo Objetivo(g/Cab/dia)"
            className={classes.consumoFormulaInput}
            placeholder="Insira um consumo"
            type="number"
            error={quantidadeTotalError}
            required
            disabled={quantidadeCalculada}
            onChange={({ target: { value } }) => setQuantidadeTotal(value)}
            value={quantidadeTotal || ""}
          />
          {quantidadeCalculada && (
            <Typography className={classes.infoMessagem}>
              O Consumo Objetivo será calculado com base nos ingredientes
              adicionados
            </Typography>
          )}
        </div>
      </div>
      {nameExistsModal && sameNameFormula && (
        <BannerNameExists
          submmitDisabled={formulacao && !formulacaoValidaParaCopia}
          sameNameFormula={sameNameFormula.Nome}
          handleClose={handleNameExistsClose}
          handleSubmmit={handleCopySameName}
        />
      )}
      <Dialog
        data-testid="Confirmation-Modal"
        open={confirmationModalOpen}
        onClose={handleConfirmationModalClose}
      >
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se o peso do saco não for informado, o sistema não poderá realizar previsões de desabastecimento de cocho para esta formulação. Deseja salvar a formulação sem peso do saco informado assim mesmo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="Confirmation-Modal-Cancel-Button" onClick={handleConfirmationModalClose} color="primary">
            Cancelar
          </Button>
          <Button data-testid="Confirmation-Modal-Submit-Button" onClick={handleConfirmationModalConfirm} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = () => {
  return {
    embalagemLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
    },
    formCadastroWrapper: {
      display: "flex",
      width: "100%",
      marginTop: 26,
      borderBottom: "solid 1px #e0e0e0",
      flexDirection: "column",
    },
    tituloFormFormulacao: {
      fontSize: 12,
      letterSpacing: 0.5,
      color: "#666",
      fontWeight: 500,
      marginTop: 24,
    },
    formFormulacao: {
      padding: "24px 0",
      display: "flex",
      flexWrap: "wrap",
      columnGap: "32px",
      rowGap: "16px",
      width: "100%",
    },
    consumoFormulaWrapper: {
      maxWidth: 226,
      display: "flex",
      flexDirection: "column",
      minWidth: 221,
      width: "100%",
      height: "100%",
    },
    consumoFormulaInput: {
      width: "100%",
    },
    formulaInput: {
      maxWidth: 200,
      width: "100%",
    },
    inputLabel: {
      fontSize: 12,
    },
    pesoInput: {
      maxWidth: 136,
      width: "100%",
    },
    selectTipos: {
      maxWidth: 216,
      width: "100%",
    },
    multiSelectTipos: {
      minWidth: 216,
    },
    textMultiSelect: {
      fontSize: 14,
      fontFamily: "Roboto",
      "--rmsc-gray": "rgba(0, 0, 0, 0.54) !important",
      "--rmsc-border": "none !important",
      "-rmsc-main": "none !important",
      borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    },
    infoMessagem: {
      fontSize: 12,
      color: "#757575",
      textAlign: "left",
      marginTop: 8,
    },
  };
};

export default withStyles(styles)(FormCadastroFormulacao);
