function arrowGenerator(color) {
  return {
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "50%",
      top: "100%",
      marginBottom: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`,
      },
    },
  };
}
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuFarol: {
    backgroundColor: "#f8f8f8",
    textAlign: "left",
    color: "#444444",
    minHeight: "50px",
    fontSize: "18px",
    letterSpacing: "0px",
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    padding: "24px",
  },
  linhaAzul: {
    minHeight: "2px",
    minWidth: "20px",
    display: "inline-block",
    backgroundColor: "#0053a1",
    borderRadius: "2px",
  },
  formControl: {
    minWidth: "100%",
    textAlign: "left",
  },
  container: {
    flexGrow: 1,
    height: "100%",
    maxHeight: "550px",
    letterSpacing: "0",
  },
  icones: {
    textAlign: "left",
    marginLeft: "10%",
    marginTop: "-10px",
  },
  linha: {
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    marginTop: "5%",
    marginRight: "0%",
  },
  legenda: {
    marginLeft: "-7.3%",
    marginBottom: "1%",
    minHeight: "5px",
    minWidth: "70px",
    display: "inline-block",
    backgroundColor: "#4fd26b",
    borderTopLeftRadius: "2px",
    borderBottomLeftRadius: "2px",
  },
  espacamento: {
    height: "15px",
  },
  barraColorida: {
    textAlign: "left",
  },
  marcadores: {
    display: "inline-block",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSetch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  Corlegenda: {
    minHeight: "5px",
    marginTop: "7px",
  },
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: "absolute",
    fontSize: 6,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  selecionado: {
    backgroundColor: "rgba(193, 221, 248, 0.2)",
  },
  fitroPeriodo: {
    marginLeft: "9.5%",
    marginRight: "9.5%",
  },
  textos: {
    display: "inline-block",
    marginLeft: "2%",
  },
  textoLegendaIcones: {
    fontSize: "14px",
    color: "#9b9b9b",
  },
  modalGeral: {
    width: "400px",
    borderRadius: "2.4px",
    boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.5)",
    backgroundColor: "#ffffff",
  },
  avatar: {
    height: "26px",
    width: "26px",
    fontSize: "12px",
    marginLeft: "35%",
  },
  avisoEntrevero: {
    color: "#ff0102",
  },
  containerAvisoEntrevero: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 10px",
    borderRadius: "10px",
    border: "solid 1px #eb394b",
    padding: "20px",
    width: "88%",
    justifyContent: "center",
    alignItems: "center",
  },
  bolinhaFarol: {
    width: "10px",
    height: "9px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  textoBolinhaFarol: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "14px",
  },
  subTextoBolinhaFarol: {
    fontSize: "13px",
    color: "#666",
  },
  tituloExplicacaoFarol: {
    fontSize: "14px",
    color: "#212121",
    fontWeight: 600,
  },
  textoExplicacaoFarol: {
    fontSize: "13px",
    color: "#424242",
  },
  linhaLegenda: {
    display: "flex",
    width: "100%",
  },
  legendContainer: {
    width: "100%",
    marginLeft: "-30px",
  },
  legendRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  legendColumn: {
    display: "inline-block",
    marginLeft: "30px",
    whiteSpace: "nowrap",
    marginBottom: "10px",
    minWidth: "110px",
  },
  cardLore: {
    display: "flex",
    alignItems: "center",
    padding: "12px 14px",
    borderRadius: "8px",
    backgroundColor: "#F3F6F7",
    color: "#0B4260",
    fontSize: "15px",
    fontWeight: "400",
    fontFamily: "Roboto",
    gap: "8px",
    margin: "4px 0",
    maxWidth: "fit-content",
  },
});
